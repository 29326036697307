.content-wrapper {
    position: relative;
    z-index: 1; /* Ensure the content stays on top */
    padding: 20px;
    color: #525252;
    display: flex;
    flex: 1 1;
.about-me-wrapper {
    flex: 1;
    padding-left: 50px;
    .about-heading {
        font-size: 48px;
        line-height: 60px;
    }
    .intro-text {
        font-size: 38px;
        margin: 0;
        line-height: 43px;
        letter-spacing: 2.1px;
    }
    .about-description {
        font-size: 22px;
        max-width: 500px;
        margin-top: 30px;
        line-height: 36px;
        font-weight: 500;
        letter-spacing: 0.8px;
        color: #525252;
    }
    .more-about-work {
        font-size: 18px;
        max-width: 560px;
        margin-top: 20px;
        line-height: 2;
        font-weight: 500;
        letter-spacing: 1.1px;
    }
    .scrollOption {
        font-size: 18px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        width: fit-content;
        padding: 20px 0 4px;
        cursor: pointer;
        line-height: 24px;
        letter-spacing: 1.1px;
        border-bottom: 2px solid;
        margin-bottom: 20px;
        font-weight: bold;
        a { 
            text-decoration: none;
            color: #525252;
        }
    }
}
.intro-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-right: 50px;
    img {
        width: 380px;
        height: 380px;
    }
}
}

.slanted-border-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 80%, 0 80%, 100% 100%, 0 calc(100% - 6vw));
    background-color: #fff;
}

@media screen and (max-width: 800px) {
    .content-wrapper {
        .about-me-wrapper {
            padding-left: 0;
            padding-right: 0;
            .about-heading {
                font-size: 20px;
                line-height: 30px;
            }
            .about-description {
                font-size: 14px;
                line-height: 20px;
            }
            .scrollOption {
                font-size: 14px;
                line-height: 20px;
            }
        }
        .intro-image-wrapper {
            display: none;
        }
    }
}
