.app {
  .clip-path-wrapper {
    border-left: 98vw solid transparent;
    border-top: 100px solid #fff;
  }

  .clip-path-wrapper-bottom {
    border-left: 98vw solid transparent;
    border-bottom: 100px solid #fff;
  }
}
