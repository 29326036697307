.more-about-me-wrapper {
    padding: 0;
    display: flex;
    flex-direction: column;
    .content-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 70px;
        h2 {
            color: #2293a3;
        }
    }
    .image-wrapper {
        display: flex;
        flex-direction: column;
    }
    @media screen and (max-width: 800px) {
        .content-wrapper {
            margin: 0 20px;
        }
        .image-wrapper {
            min-height: 56vh;
        }
    }
}