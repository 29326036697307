.view-details-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    .back-button-wrapper {
        position: absolute;
        left: 17px;
        top: 10px;
        cursor: pointer;
    }
}