.portfolio-wrapper {
    position: relative;
    .portfolio-title {
        display: flex;
        align-items: stretch;
        justify-content: center;
        margin-top: 18px;
        margin-bottom: 45px;
        font-size: 40px;
        color: #525252;
        line-height: 60px;
        font-weight: 500;
    }

    .portfolio-container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0 70px;
        .project-wrapper {
            display: flex;
            width: 100%;
            margin-bottom: 50px;
            .project-content {
                display: flex;
                flex-grow: 1;
                width: 100%;
            }
            &:nth-child(even) {
                .project-image-wrapper {
                    justify-content: flex-end;
                }
            }
            .project-image-wrapper {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                a {
                    display: flex;
                    border-radius: 31px;
                    box-shadow: 0 20px 40px hsla(0, 0, 32%, 0.34);
                    img {
                        height: 298px;
                        width: 806px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .portfolio-container {
            padding: 0 20px;
            .project-wrapper {
                .project-image-wrapper a img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .portfolio-title {
            font-size: 20px;
            line-height: 30px;
        }
    }
}