.content {
    display: flex;
    .contact-us-intro {
        font-size: 32px;
        align-self: center;
    }
    color: #525252;
    flex-direction: column;
    .links-wrapper {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .content-left{
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            .email {
                padding-top: 14px;
                a {
                    color: #2293a3;
                    text-decoration: none;
                    vertical-align: super;
                }
            }
        }
        .content-right {
            ul {
                margin: 0;
                line-height: normal;
                padding: 0;
                list-style-type: none;
                li {
                    font-size: 16px;
                    line-height: 39px;
                    a {
                        color: #2293a3;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                    }
                }
            }
        }
    }
    @media screen  and (max-width: 800px) {
        font-size: 14px;
        align-items: center;
        padding-left: 30px;
        .contact-us-intro {
            font-size: 20px;
        }
        .links-wrapper {
            padding-left: 10px;
            flex-wrap: wrap;
            margin-top: 20px;
            .content-left {
                font-size: 14px;
                .email {
                    padding-top: 10px;
                }
            }
            .content-right {
                ul {
                    float: left;
                    li {
                        a {
                            font-size: 14px;
                        }
                    }
                }

            }
        }
    }
}
